import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../auth.service';
import { Router } from '@angular/router';
import { ModalStartComponent } from '../../../modal-start/modal-start.component';
import { MatDialog } from '@angular/material/dialog';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();



  get infinity() {
    let permissions = this.ngxPermissionService.getPermissions();
    return permissions['infinity::access'] !== undefined;
  }

  constructor(public oidcService: OidcSecurityService, public router: Router, public dialog: MatDialog, public ngxPermissionService: NgxPermissionsService,) { }

  ngOnInit() { }

  openModal() {
   
    let dialogRef = this.dialog.open(ModalStartComponent, {
      height: '470px',
      width: '620px',
    });
  }

  toggleSideBar() {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }
  logout() {
    this.oidcService.logoff();
  }
}
