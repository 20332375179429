<div *ngxPermissionsOnly="['loaded']" class="container_full" style="padding-top: 1px;" [style.background-color]="infinity ? '#212121' : null">
  <div class="container">
    <section>
      <div class="">
        <div class="row">
          <div class="col-12 col-md-9">
            <h3 [style.color]="infinity ? 'white' : null"
              [style.border-left]="infinity ? '4px solid #fff' : '4px solid #0da5ba'">
              Ferramentas
            </h3>
            <div class="row">
              <div class="col-6 col-md-4">
                <a class="item" [href]="['https:/', 'apolo.' +  baseUrl].join('/')">
                  <div class="image">
                    <img
                      [src]=" infinity ? '/assets/images/card-i.50-infinity-02.png' : '/assets/images/banner_apolo50.jpg' "
                      alt="">
                  </div>
                </a>
              </div>
              <div class="col-6 col-md-4">
                <a class="item" [href]="['https:/', 'oraculo.' +  baseUrl].join('/')">
                  <div class="image">
                    <img
                      [src]=" infinity ? '/assets/images/card-i.50-infinity-03.png' : '/assets/images/banner_oraculo.jpg' "
                      alt="">
                  </div>
                </a>
              </div>
              <div class="col-6 col-md-4">
                <a class="item" [href]="['https:/', 'selectus.' +  baseUrl].join('/')">
                  <div class="image">
                    <img
                      [src]=" infinity ? '/assets/images/card-i.50-infinity-04.png' : '/assets/images/banner_selectus50.jpg' "
                      alt="">
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <h3 [style.border-left]="infinity ? '4px solid #fff' : '4px solid #0e40bb'"
              [style.color]="infinity ? 'white' : null">
              Treinamento
            </h3>
            <div class="row">
              <div class="col-6 col-md-12">
                <a class="item" [href]="['https://empreenderdinheiro.com.br/masterplan-investidor-5-0/']">
                  <div class="image">
                    <img src="/assets/images/banner_mp50.jpg"
                      [src]=" infinity ? '/assets/images/card-i.50-infinity-05.png' : '/assets/images/banner_mp50.jpg' "
                      alt="">
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div style="height: 42px;"></div>
    <div class="row">
      <div class="col-12 col-md-3">
        <h3 [style.border-left]="infinity ? '4px solid #fff' : '4px solid #15b84f'"
          [style.color]="infinity ? 'white' : null">
          Aumente sua renda
        </h3>
        <div class="row">
          <div class="col-6 col-md-12">
            <a class="item" [href]="['https://empreenderdinheiro.com.br/smartclasspop-techfinance/']">
              <div class="image">
                <img src="/assets/images/banner_ipro.jpg"
                  [src]=" infinity ? '/assets/images/card-i.50-infinity-01.png' : '/assets/images/card-i.50-basic-01.png' "
                  alt="">
              </div>
            </a>
          </div>
        </div>
      </div>
      <div *ngIf="infinity===true" class="col-12 col-md-6">
        <h3 [style.border-left]="infinity ? '4px solid #fff' : '4px solid #15b84f'"
          [style.color]="infinity ? 'white' : null">
          Carteiras Recomendadas
        </h3>
        <div class="row">
          <div  class="col-6 col-md-6">
            <a class="item" [href]="['https://empreenderdinheiro.com.br/honesta-2/']">
              <div class="image">
                <img src="/assets/images/card-i.50-infinity-06.png" alt="">
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
      </div>
    </div>
    <div style="height: 42px;"></div>
    <div class="row">
      <div class="col-12 col-md-9">
        <h3 [style.color]="infinity ? 'white' : null"
          [style.border-left]="infinity ? '4px solid #fff' : '4px solid #0da5ba'">
          Cursos rápidos
        </h3>
        <div class="row">
          <div class="col-6 col-md-4">
            <a class="item" [href]="['https://vimeo.com/showcase/tesouro-direto-ao-ponto']">
              <div class="image">
                <img
                  [src]=" infinity ? '/assets/images/card-i.50-infinity-07.png' : '/assets/images/card-i.50-infinity-07.png' "
                  alt="">
              </div>
            </a>
          </div>
          <div class="col-6 col-md-4">
            <a class="item" [href]="['https://vimeo.com/showcase/como-perder-o-medo-de-investir-em-acoes']">
              <div class="image">
                <img
                  [src]=" infinity ? '/assets/images/card-i.50-infinity-08.png' : '/assets/images/card-i.50-infinity-08.png' "
                  alt="">
              </div>
            </a>
          </div>
          <div class="col-6 col-md-4">
            <a class="item" [href]="['https://vimeo.com/showcase/reviravolta-financeira']">
              <div class="image">
                <img
                  [src]=" infinity ? '/assets/images/card-i.50-infinity-09.png' : '/assets/images/card-i.50-infinity-09.png' "
                  alt="">
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <h3 [style.border-left]="infinity ? '4px solid #fff' : '4px solid #0e40bb'"
          [style.color]="infinity ? 'white' : null">
          Investimentos
        </h3>
        <div class="row">
          <div class="col-6 col-md-12">
            <a class="item" [href]="['https://empreenderdinheiro.com.br/fabrica-de-milionarios-i5']">
              <div class="image">
                <img src="/assets/images/banner_mp50.jpg"
                  [src]=" infinity ? '/assets/images/card-i.50-infinity-10.png' : '/assets/images/card-i.50-infinity-10.png' "
                  alt="">
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
